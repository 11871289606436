const API_URL = process.env.REACT_APP_API_URL || '';

export const API_END_POINTS = {

    ADMIN_LOGIN                      : `${API_URL}/secure/tokens`,
    ADMIN_VERIFY_OTP                 : `${API_URL}/secure/tokens/verify_otp`,
    ADMIN_VERIFY_TOKEN               : `${API_URL}/secure/tokens/verify`,
    ADMIN_NEW_AD_LOGIN               : `${API_URL}/secure/tokens/ad_new`,
    ADMIN_LOGOUT                     : `${API_URL}/secure/tokens/logout`,
    ADMIN_LOGOUT_NOENC               : `${API_URL}/secure/tokens/logout_noenc`,
    ADMIN_DEPOSITS_LIST              : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_CREATE_DEPOSIT             : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_SHOW_DEPOSIT               : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_SHOW_SIP                   : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_CREATE_SIP                 : `${API_URL}/secure/one_click_checkouts/create_sip`,
    ADMIN_SIP_LIST                   : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_WITHDRAWAL_LIST            : `${API_URL}/secure/one_click_checkouts`,
    ADMIN_GENERIC_LINKS_LIST         : `${API_URL}/generic/checkouts`,
    ADMIN_CREATE_GENERIC_LINK        : `${API_URL}/generic/checkouts`,
    ADMIN_SHOW_GENERIC_LINK          : `${API_URL}/generic/checkouts`,
    ADMIN_BATCH_UPLOAD_LIST          : `${API_URL}/secure/bulk_uploads`,
    ADMIN_BATCH_UPLOAD               : `${API_URL}/secure/one_click_checkouts/create_with_file`,
    ADMIN_DOWNLOAD_SAMPLE_BATCH_FILE : `${API_URL}/secure/one_click_checkouts/example_file`,

    ADMIN_WEBHOOKS                   : `${API_URL}/secure/webhooks/new`,
    ADMIN_CURRENT_WEBHOOKS           : `${API_URL}/secure/webhooks`,
    ADMIN_UPDATE_WEBHOOK             : `${API_URL}/secure/webhooks/update`,

    ADMIN_AMCS                       : `${API_URL}/secure/amcs`,
    ADMIN_FUNDS                      : `${API_URL}/secure/funds`,
    ADMIN_CHECK_PAN                  : `${API_URL}/secure/onboardings/check_pan/`,
    ADMIN_FETCH_FOLIO                : `${API_URL}/secure/accounts/fetch/`,

    ADMIN_LANDING_PAGES              : `${API_URL}/secure/landing_pages`,
    ADMIN_WHATSAPP_TEMPLATES         : `${API_URL}/secure/whatsapp_templates`,
    BULK_UPLOAD_LEAD                 : `${API_URL}/crm/leads/bulk_upload`,
    LEADS                            : `${API_URL}/crm/leads`,
    SHOW_LEADS                       : `${API_URL}/crm/leads`,
    CREATE_LEADS                     : `${API_URL}/crm/leads`, 
    DELETE_LEAD                      : `${API_URL}/crm/leads`,
    UPDATE_LEAD                      : `${API_URL}/crm/leads`,
    LEAD_RECOMMENDATIONS             : `${API_URL}/crm/lead_recommendations`,
    CREATE_LEAD_RECOMMENDATIONS      : `${API_URL}/crm/lead_recommendations`,
    CONVERT_TO_SALE_LEAD             : `${API_URL}/crm/leads/convert_to_sale`,
    BENCHMARKS                       : `${API_URL}/crm/benchmarks`,

    GET_LEAD_NOTE_KEYWORDS           : `${API_URL}/crm/keywords`,
    CREATE_LEAD_NOTES                : `${API_URL}/crm/lead_notes`,
    CREATE_LEAD_REMINDERS            : `${API_URL}/crm/lead_reminders`,
    TRIGGER_LEAD_REMINDER            : `${API_URL}/crm/lead_reminders/trigger`,

    RISK_PROFILES                    : `${API_URL}/crm/risk_profiles`,

    MESSAGE_TEMPLATES                : `${API_URL}/crm/message_templates`,

    HANDSHAKE                        : `${API_URL}/clients`,

    WHATSAPP_JOURNEYS                : `${API_URL}/secure/whatsapp_journeys`,
};