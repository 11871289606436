import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { Badge } from "src/components/theme";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
// import styles from "./_generic-links-table.module.scss";
import EditWhatsappJourney from "../edit-whatsapp-journey";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import _ from 'lodash';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WhatsappJourneysTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);

  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <strong>Name</strong>,
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedRowData(row)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: <strong>Status</strong>,
        accessor: "status",
        id: "status",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong>Category</strong>,
        accessor: "category",
        id: "category",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong>ID</strong>,
        accessor: "uuid",
        id: "uuid",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      <Dialog
        fullScreen
        open={selectedRowData?.uuid}
        onClose={() => setSelectedRowData(null)}
        TransitionComponent={Transition}
      >
        <EditWhatsappJourney
          show={selectedRowData?.uuid}
          whatsappJourney={selectedRowData}
          handleClose={() => setSelectedRowData(null)}
        />
      </Dialog>
    </>
  )
}

export default WhatsappJourneysTable;
