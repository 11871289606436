import React, { Component } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import EventBus from "eventing-bus";
import { Label } from "src/components/theme";
import { ACTION_TYPES } from "src/constants";
import { paymentMethods } from "src/constants";
import { TextInputOne } from "src/components/form-inputs";
// import styles from "./_read-modal.module.scss";
import { isArray } from "lodash";
import Select from "react-select";
import _ from "lodash";
import { Grid, Stack, Button, Dialog, ListItemText, ListItem, List, Divider, AppBar,
Toolbar, IconButton, Typography, TextField, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ReactJsonView from '@microlink/react-json-view'

export default class EditWhatsappJourney extends Component {
  state = {
    isLoading: false,
    phoneNumber: null,
    isLoadingSendMessage: false,
    isLoadingSave: false,
    previewUrl: null,
    jsonStructure: {},
    newJsonStructure: null,
  };

  componentDidMount() {
    this.requestWhatsappJourneyPreview = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW,
      () => this.setState({ isLoading: true })
    );
    this.requestWhatsappJourneyPreviewSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW_SUCCEEDED,
      this.onPreviewFetchSuccess
    );
    this.requestWhatsappJourneyPreviewFailure = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.requestWhatsappJourney = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY,
      () => this.setState({ isLoading: true })
    );
    this.requestWhatsappJourneySuccess = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_SUCCEEDED,
      this.onJourneyFetchSuccess
    );
    this.requestWhatsappJourneyFailure = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.requestWhatsappJourneySendMessage = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE,
      () => this.setState({ isLoadingSendMessage: true })
    );
    this.requestWhatsappJourneySendMessageSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE_SUCCEEDED,
      () => { toast.success("Message sent successfully!", "success"); this.setState({ isLoadingSendMessage: false }) }
    );
    this.requestWhatsappJourneySendMessageFailure = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE_FAILED,
      () => this.setState({ isLoadingSendMessage: false })
    );

    this.requestWhatsappJourneyUploadJson = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON,
      () => this.setState({ isLoadingSave: true })
    );
    this.requestWhatsappJourneyUploadJsonSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON_SUCCEEDED,
      this.onUpdateSuccess
    );
    this.requestWhatsappJourneyUploadJsonFailure = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON_FAILED,
      () => this.setState({ isLoadingSave: false })
    );

    this.props.requestWhatsappJourneyPreview({flow_id: this.props.whatsappJourney.uuid})
    this.props.requestWhatsappJourney({flow_id: this.props.whatsappJourney.uuid})
  }

  componentWillUnmount() {
    this.requestWhatsappJourneyPreview();
    this.requestWhatsappJourneyPreviewSuccess();
    this.requestWhatsappJourneyPreviewFailure();

    this.requestWhatsappJourneySendMessage();
    this.requestWhatsappJourneySendMessageSuccess();
    this.requestWhatsappJourneySendMessageFailure();

    this.requestWhatsappJourney()
    this.requestWhatsappJourneySuccess()
    this.requestWhatsappJourneyFailure()

    this.requestWhatsappJourneyUploadJson()
    this.requestWhatsappJourneyUploadJsonSuccess()
    this.requestWhatsappJourneyUploadJsonFailure()
  }

  onPreviewFetchSuccess = (response) => {
    this.setState({previewUrl: response.link})
  }

  onJourneyFetchSuccess = (response) => {
    this.setState({jsonStructure: JSON.parse(response.jsonStructure)})
  }

  onUpdate = () => {
    console.log(this.state.newJsonStructure)
    this.props.requestWhatsappJourneyUploadJSON({
      flow_id: this.props.whatsappJourney.uuid,
      whatsapp_flow: { json_structure: JSON.stringify(this.state.newJsonStructure) }
    })
  };

  onUpdateSuccess = () => {
    toast.success("Updated successfully!", "success");
    this.setState({ isLoadingSave: false })
  };

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({phoneNumber: phoneNumber})
  }

  handleSendMessage = () => {
    this.props.requestSendWhatsappJourneyMessage({
      flow_id: this.props.whatsappJourney.uuid,
      whatsapp_flow: {
        phone_number: this.state.phoneNumber
      }
    })
  }

  render() {
    let { show, handleClose, whatsappJourney, landingPages } = this.props;
    whatsappJourney = whatsappJourney || {}

    return (
      <>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Journey
            </Typography>
            {
              this.state.isLoadingSave ?
                <CircularProgress color="inherit" />
              :
                <Button autoFocus color="inherit" onClick={this.onUpdate} disabled={this.state.newJsonStructure == null}>
                  save
                </Button>
            }
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} style={{height: '100%'}}>
          <Grid item xs={3}>
            <List>
              <ListItem>
                <ListItemText primary={whatsappJourney.name} secondary="Name" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary={whatsappJourney.category} secondary="Category"/>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary={whatsappJourney.status} secondary="Status"/>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Send Journey" secondary="Enter phone number and submit"/>
              </ListItem>
              <ListItem>
                <Grid container spacing={2} style={{alignItems: 'center'}}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Phone number"
                      sizeType="medium"
                      onChange={(event) => this.handlePhoneNumberChange(event.target.value) }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {
                      this.state.isLoadingSendMessage ?
                        <CircularProgress />
                      :
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.handleSendMessage}
                          aria-label="close"
                        >
                          <SendIcon />
                        </IconButton>
                    }
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={5}>
            <ReactJsonView
              src={this.state.jsonStructure}
              style={{minHeight: '100%', overflow: 'scroll', height: '200px'}}
              onEdit={(obj) => {this.setState({newJsonStructure: obj.updated_src}) ;return true}}
              onAdd={(obj) => {this.setState({newJsonStructure: obj.updated_src}) ;return true}}
              onDelete={(obj) => {this.setState({newJsonStructure: obj.updated_src}) ;return true}}
            />
          </Grid>

          <Grid item xs={4} style={{height: '100%'}}>
            {
              this.state.previewUrl &&
                <iframe src={this.state.previewUrl} width="100%" height="100%" ></iframe>
            }
          </Grid>
        </Grid>
      </>
    );
  }
}
