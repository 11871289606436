import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { getWhatsappJourneys, createWhatsappJourney, getWhatsappJourneyPreviewURL, uploadJSONWhatsappJourney,
sendWhatsappJourneyMessage, getWhatsappJourney } from "src/redux/api";
import {
  requestWhatsappJourneysSuccess,
  requestWhatsappJourneysFailure,
  requestWhatsappJourneyPreviewSuccess,
  requestWhatsappJourneyPreviewFailure,
  requestCreateWhatsappJourneySuccess,
  requestCreateWhatsappJourneyFailure,
  requestWhatsappJourneyUploadJSONSuccess,
  requestWhatsappJourneyUploadJSONFailure,
  requestSendWhatsappJourneyMessageSuccess,
  requestSendWhatsappJourneyMessageFailure,
  requestWhatsappJourneySuccess,
  requestWhatsappJourneyFailure,
} from "src/redux/actions";

export function* watchWhatsappJourneysApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_WHATSAPP_JOURNEYS, requestWhatsappJourneys);
  yield takeEvery(ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY, requestWhatsappJourney);
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_WHATSAPP_JOURNEY, requestCreateWhatsappJourneys);
  yield takeEvery(ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW, requestWhatsappJourneyPreview);
  yield takeEvery(ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON, requestWhatsappJourneyUploadJSON);
  yield takeEvery(ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE, requestSendWhatsappJourneyMessage);
}

function* requestWhatsappJourneys(action) {
  try {
    const data = yield call(getWhatsappJourneys, action.payload);
    yield put(requestWhatsappJourneysSuccess(data));
  } catch (e) {
    yield put(requestWhatsappJourneysFailure(e));
  }
}

function* requestWhatsappJourney(action) {
  try {
    const data = yield call(getWhatsappJourney, action.payload);
    yield put(requestWhatsappJourneySuccess(data));
  } catch (e) {
    yield put(requestWhatsappJourneyFailure(e));
  }
}

function* requestCreateWhatsappJourneys(action) {
  try {
    const data = yield call(createWhatsappJourneys, action.payload);
    yield put(requestCreateWhatsappJourneySuccess(data));
  } catch (e) {
    yield put(requestCreateWhatsappJourneyFailure(e));
  }
}

function* requestWhatsappJourneyPreview(action) {
  try {
    const data = yield call(getWhatsappJourneyPreviewURL, action.payload);
    yield put(requestWhatsappJourneyPreviewSuccess(data));
  } catch (e) {
    yield put(requestWhatsappJourneyPreviewFailure(e));
  }
}

function* requestWhatsappJourneyUploadJSON(action) {
  try {
    const data = yield call(uploadJSONWhatsappJourney, action.payload);
    yield put(requestWhatsappJourneyUploadJSONSuccess(data));
  } catch (e) {
    yield put(requestWhatsappJourneyUploadJSONFailure(e));
  }
}

function* requestSendWhatsappJourneyMessage(action) {
  try {
    const data = yield call(sendWhatsappJourneyMessage, action.payload);
    yield put(requestSendWhatsappJourneyMessageSuccess(data));
  } catch (e) {
    yield put(requestSendWhatsappJourneyMessageFailure(e));
  }
}