import { ACTION_TYPES } from 'src/constants';

export function requestWhatsappJourneys(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEYS,
    payload,
  };
}

export function requestWhatsappJourneysSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEYS_SUCCEEDED,
    payload,
  };
}

export function requestWhatsappJourneysFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEYS_FAILED,
    payload,
  };
}

export function requestWhatsappJourney(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY,
    payload,
  };
}

export function requestWhatsappJourneySuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_SUCCEEDED,
    payload,
  };
}

export function requestWhatsappJourneyFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_FAILED,
    payload,
  };
}

export function requestWhatsappJourneyPreview(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW,
    payload,
  };
}

export function requestWhatsappJourneyPreviewSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW_SUCCEEDED,
    payload,
  };
}

export function requestWhatsappJourneyPreviewFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_PREVIEW_FAILED,
    payload,
  };
}

export function requestCreateWhatsappJourney(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_WHATSAPP_JOURNEY,
    payload,
  };
}

export function requestCreateWhatsappJourneySuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_WHATSAPP_JOURNEY_SUCCEEDED,
    payload,
  };
}

export function requestCreateWhatsappJourneyFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_WHATSAPP_JOURNEY_FAILED,
    payload,
  };
}

export function requestWhatsappJourneyUploadJSON(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON,
    payload,
  };
}

export function requestWhatsappJourneyUploadJSONSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON_SUCCEEDED,
    payload,
  };
}

export function requestWhatsappJourneyUploadJSONFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_JOURNEY_UPLOAD_JSON_FAILED,
    payload,
  };
}

export function requestSendWhatsappJourneyMessage(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE,
    payload,
  };
}

export function requestSendWhatsappJourneyMessageSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE_SUCCEEDED,
    payload,
  };
}

export function requestSendWhatsappJourneyMessageFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_WHATSAPP_JOURNEY_MESSAGE_FAILED,
    payload,
  };
}