import { connect } from "react-redux";
import {
  requestWhatsappJourneys,
  // requestAdminCreateGenericLinkClear
} from "src/redux/actions";
import WhatsappJourneys from "./whatsapp-journeys";

const mapStateToProps = (state) => ({
  whatsappJourneys: state.whatsappJourneys
});

export default connect(mapStateToProps, {
  requestWhatsappJourneys,
  // requestAdminCreateGenericLinkClear,
})(WhatsappJourneys);
