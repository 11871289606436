import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function getWhatsappJourneys(data) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}`,
  }).then((response) => response.data.flows);
}

export function getWhatsappJourney(data) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}/${data.flow_id}`,
  }).then((response) => response.data);
}

export function createWhatsappJourney(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}`,
    data,
  }).then((response) => response.data);
}

export function getWhatsappJourneyPreviewURL(data) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}/${data.flow_id}/visualize`,
  }).then((response) => response.data);
}

export function uploadJSONWhatsappJourney(data) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}/${data.flow_id}/json_structure`,
    data,
  }).then((response) => response.data);
}

export function sendWhatsappJourneyMessage(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: `${API_END_POINTS.WHATSAPP_JOURNEYS}/${data.flow_id}/send_message`,
    data,
  }).then((response) => response.data);
}