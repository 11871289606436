import { connect } from "react-redux";
import {requestWhatsappJourneyPreview, requestSendWhatsappJourneyMessage,
requestWhatsappJourney, requestWhatsappJourneyUploadJSON} from "src/redux/actions";
import EditWhatsappJourney from "./edit-whatsapp-journey";

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
	requestWhatsappJourneyPreview, requestSendWhatsappJourneyMessage,
	requestWhatsappJourney, requestWhatsappJourneyUploadJSON
})(EditWhatsappJourney);
