import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function WhatsappJourneysReducer(state = INITIAL_STATE.whatsappJourneys, action) {
  switch (action.type) {

    case ACTION_TYPES.REQUEST_WHATSAPP_JOURNEYS_SUCCEEDED: {
      return action.payload.flows
    }

    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.whatsappJourneys;

    default:
      return state;
  }
}